import { useParams, useLoaderData } from 'react-router-dom';
import ItemElement from './ItemElement';
import Item from '../types/Item';
import { getCategory, getValue } from '../utils/valueUtils';
import { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../contexts/SiteContext';

function Page() {
  const { site, getItemLayout } = useContext(SiteContext)
  const params = useParams()
  const item = useLoaderData() as Item
  const [layout, setLayout] = useState({} as any);
  let layoutName = params.layout ?? 'full';
  let className = layoutName + ' page' + item?.id;
  let title = getValue(item, 100);
  let categoryTitle = null;
  document.title = title;
  useEffect(() => {
    // Мэдээллийн ангиллаас хамаарч харуулах хэлбэрийг өөрчилнө
    const category = getCategory(site, item.category);
    if (category) {
      setLayout(getItemLayout(category, layoutName));
    }
  }, [layoutName, item.category]);
  /*if (categoryId) {
    if (categoryId === '0') {
      item = site;
    } else {
      item.category = parseInt(categoryId);
    }
  };
  const handleCancel = (item: any) => {
    if (item.parent > 0) {
      redirect("items/" + item.parent + "/categories/" + item.category + "/table.html");
    } else {
      redirect("categories/" + item.category + "/table.html");
    }
  };
  const handleSave = (item: any) => {
    if (item.parent > 0) {
      redirect("items/" + item.parent + "/categories/" + item.category + "/table.html");
    } else {
      redirect("categories/" + item.category + "/table.html");
    }
  };*/
  return item && <div className={className}>
    <ItemElement item={item} layout={layout} />
  </div>;
}
export default Page;