import * as Service from './Service';
/**
 * Вебийн тохиргоог удирдах сервис
 */
const alias = 'site'
/**
 * Вебийн тохиргоог удирдах сервис
 */
export const get = () => Service.get(alias, undefined)
/**
 * Мэдээлэл нэмнэ
 * @param {object} item Мэдээлэл
 * @returns 
 */
export const add = (item: any) => Service.add(alias, item)
/**
 * Мэдээлэл засна
 * @param {object} item Мэдээлэл
 * @returns 
 */
export const edit = (item: any) => Service.edit(alias, undefined, item)
/**
 * Мэдээлэл устгана
 * @param {number} id Устгах мэдээллийн дугаар
 * @returns
 */
// export const remove = (id: number) => Service.remove(alias, id)