import { FC, useContext } from 'react';
import EditElementProps from '../../types/EditElementProps';
import { SiteContext } from '../../contexts/SiteContext';
import { getValues, getValue } from '../../utils/valueUtils';
import Dg8 from './Dg8';
// menu-edit
const Dg11: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const { site } = useContext(SiteContext);
	const menus = getValues(site, 2) // цэсийн талбарын дугаар: 2
	const propertiesClone = { ...properties };
	properties.values = [...properties.values, { field: 'options', value: menus.map((menu: any) => [menu.name, getValue(menu, 'title')]) }];
	return <Dg8 properties={propertiesClone} value={value} onChange={onChange} />;
}
export default Dg11;