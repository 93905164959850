import { FC } from 'react';
import WidgetProps from '../../types/WidgetProps';
import { getValue } from '../../utils/valueUtils';
// Google Analytics
const Dg5019: FC<WidgetProps> = ({ properties }) => {
    const loadAnalytics = (key: any) => {
        const w:any = window;
        w.dataLayer = w.dataLayer || [];
        w.gtag = function () { w.dataLayer.push(arguments); }
        w.gtag('js', new Date());
        w.gtag('config', key);

        (function (d, s, id) {
            var js: any, fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://www.googletagmanager.com/gtag/js?id=' + key;
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-analytics'));
    };
    loadAnalytics(getValue(properties, 'key'));
    return null;
}
export default Dg5019;