import { useState } from 'react';
import { Link, redirect } from 'react-router-dom';
import * as MeService from '../services/MeService';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');

    const handleRegister = () => {
        var msg;
        if (password !== repassword) {
            msg = 'Нууц үг зөрж байна.';
        }
        if (email === '') {
            msg = 'Цахим шуудангийн хаягаа бичнэ үү.';
        }
        if (msg) {
            alert(msg);
            return;
        }
        MeService.signUp(email, password).then(data => data.json()).then((value: any) => {
            if (value.id > 0) {
                alert('Амжилттай бүртгэгдлээ. Цахим шуудангаа баталгаажуулна уу.');
                redirect('/');
            }
        });
    };
    return <div className="login-box">
        <h2></h2>
        <form role="form" onSubmit={handleRegister} className="box">
            <div className="form-group">
                <label>Цахим шуудан</label>
                <input title="Цахим шуудан" type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="form-group">
                <label>Нууц үг</label>
                <input title="Нууц үг" type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} />
            </div>
            <div className="form-group">
                <label>Нууц үгээ давтах</label>
                <input title="Нууц үгээ давтах" type="password" className="form-control" value={repassword} onChange={e => setRepassword(e.target.value)} />
            </div>
            <div className="form-actions">
                <button type="submit" className="btn btn-primary"><i className="fa fa-unlock"></i> Бүртгүлэх</button>
            </div>
            <p>
                <Link to="/">Нэвтрэх хуудас руу буцах</Link>
            </p>
        </form>
    </div>
}
export default Register;