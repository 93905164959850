import { createContext, ReactNode, useEffect, useState } from 'react';
import { UserContextType } from '../types/UserContextType';
import * as MeService from '../services/MeService';
import User from '../types/User';

export const UserContext = createContext<UserContextType>({ user: {} as User, setUser: (user: User) => { } });

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    // Хандаж буй хэрэглэгчийн мэдээллийг агуулна.
    const [user, setUser] = useState<User>({} as User);
    const [loading, setLoading] = useState<Boolean>(true);

    useEffect(() => {
        const init = async () => {
            // Хэрэглэгчийн мэдээллийг авна.
            setUser(await (await MeService.get()).json());
            setLoading(false)
        }
        init()
    }, [])

    const value = { user, setUser }

    return loading ? null :
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
}
