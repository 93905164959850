import { FC } from 'react';
import ElementProps from '../../types/ElementProps';
import Element from '../Element';
/**
 * layout-view
 * @param param0 
 * @returns 
 */
const Dg1000: FC<ElementProps> = ({ value }) => {
    return <Element properties={value.value} />;
}
export default Dg1000;