import { FC } from 'react';
import EditElementProps from '../../types/EditElementProps';
import { reduceValues } from '../../utils/valueUtils';
// number-edit
const Dg16: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const { title, required, readonly, min, max, step } = reduceValues(properties);
	return <input type="number" placeholder={title} required={required} readOnly={readonly}
		value={value.value ?? ''} onChange={e => onChange(e.target.value)}
		step={step ? step : 'any'} min={min} max={max} />;
}
export default Dg16;