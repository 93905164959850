import Item from "../types/Item";
import Site from "../types/Site";
import Value from "../types/Value";

export const slugify = (text: string) =>
	text.toLowerCase().replace(/[\?\-_,:\/\\"\\\'\|\#\&%]+/g, ' ').replace(/ +/g, '-')

export const initCategories = (site: Site) => {
	var categories: any = {};
	var addToCategories = (category: any) => {
		if (!(category.id in categories)) {
			categories[category.id] = { ...category };
			if ('items' in category) {
				for (var j in category.items) {
					category.items[j].parentId = category.id;
					addToCategories(category.items[j]);
				}
			}
		}
	};
	var value = getValue(site, 1);
	if (value) {
		addToCategories({ ...value });
	}
	value = getValue(site.default, 1);
	if (value) {
		addToCategories({ ...value });
	}
	return categories;
}

export const getParentCategory = (site: Site, categoryId: number) => {
	let parentCategory: any;
	var find = (category: any) => {
		if (category.id === categoryId) {
			return true
		} else {
			if ('items' in category) {
				parentCategory = category
				for (let child of category.items) {
					if (find(child)) {
						return true;
					}
				}
			}
		}
	};
	var value = getValue(site, 1);
	if (value) {
		if (find(value)) {
			return parentCategory;
		}
	}
	value = getValue(site.default, 1);
	if (value) {
		if (find(value)) {
			return parentCategory;
		}
	}
	return null;
}

export const getCategory = (site: Site, categoryId: number) => {
	var find = (category: any) => {
		if (category.id === categoryId) {
			return category;
		} else {
			if ('items' in category) {
				for (let child of category.items) {
					if (find(child)) {
						return child;
					}
				}
			}
		}
	};
	var value = getValue(site, 1);
	if (value) {
		value = find(value);
		if (value) {
			return value;
		}
	}
	value = getValue(site.default, 1);
	if (value) {
		value = find(value);
		if (value) {
			return value;
		}
	}
	return null;
}

export const getCategories = (site: Site) => {
	let categories: any[] = [];
	var collect = (category: any) => {
		categories.push(category);
		if ('items' in category) {
			for (let child of category.items) {
				collect(child);
			}
		}
	};
	var value = getValue(site, 1);
	if (value) {
		collect(value);
	}
	value = getValue(site.default, 1);
	if (value) {
		collect(value);
	}
	return categories;
}

const getLayout = (item: Item, name: string) => {
	if (item.values) {
		let layouts = item.values.filter((value: Value) => value.field === 3 && name === value.value.name);
		if (layouts.length > 0) {
			return layouts[0].value;
		}
	}
	return null;
};

export const getSiteLayouts = (site: Site) => {
	let layouts: any = {};
	if (site) {
		// Вебийн хөл, толгойн тохиргоог олно
		layouts.header = getLayout(site, 'header');
		layouts.footer = getLayout(site, 'footer');
	}
	if (site.default) {
		// Вебийн системийн хөл, толгойн тохиргоог олно
		layouts.sysHeader = { ...getLayout(site.default, 'header'), name: 'sysHeader' };
		layouts.sysFooter = { ...getLayout(site.default, 'footer'), name: 'sysFooter' };
	}
	return layouts;
};

export const addValue = (item: any, field: any, value: any) => {
	var values = item.values.filter((value: any) => value.field === field.id);
	if (field.multi) {
		for (var i in values) {
			if (values[i].value == null || values[i].value == '') {
				values[i].value = value;
			}
		}
	} else {
		values[0].value = value;
	}
}

export const getFields = (site: Site, catId: number) => {
	var fields: any[] = [];
	var category = getCategory(site, catId);
	if (getValue(category, 'inherit')) {
		while (category != null) {
			if (category.fields) {
				fields = fields.concat(category.fields);
			}
			category = getParentCategory(site, category.id);
		}
	} else {
		fields = fields.concat(category.fields);
	}
	return fields;
}

export const collectFields = (items: any) => {
	var result: any[] = [];
	for (var i in items) {
		if ('items' in items[i]) {
			result = result.concat(collectFields(items[i].items));
		} else if ('id' in items[i]) {
			result.push(items[i]);
		}
	}
	return result;
}

export const getMultiParents = (site: Site, category: any) => {
	var path = [category.id];
	while ('parentId' in category && getParentCategory(site, category.id).multi) {
		path.push(category.parentId);
		category = getParentCategory(site, category.id);
	}
	return path;
}

export const getValue = (item: any, fieldId: number | string) => {
	if (item && item.values) {
		var values = item.values.filter((value: any) => value.field === fieldId);
		return values.length > 0 ? values[0].value : null;
	}
	return null;
}

export const getValues = (item: any, fieldId: number | string) => (item && item.values ?
	item.values.filter((value: any) => value.field === fieldId).map((value: any) => value.value) : [])

export const reduceValues = (item: any) => (item && item.values ?
	item.values.reduce((value: any, obj: any) => {
		const field = value.field === 'class' ? 'className' : value.field;
		if (!(field in obj)) {
			obj[field] = value.value;
		}
		return obj;
	}, {}) : {})
/**
 * Эх бүтцийн тохиргоог хүүд нэмж, үндсэн мэдээллийг өөрлөхгүйээр бүх бүтцийг
 * id-аар нь түлхүүр хийж обьект болгон буцаана
 * @param item 
 * @param layoutName 
 * @returns 
 */
export const collectLayout = (item: object, layoutName: string) => {
	const result: any = {};
	const collect = (parentItems: any[], current: any) => {
		// Утгын төрөл нь layout бөгөөд нэр нэр нь layoutName байх утгуудыг ялгаж авна.
		let index = current.values.findIndex((value: any) =>
			value.field === 'layout' && value.value.name === layoutName);
		// Утга олдсон бол эхний бүтэцтэй тухайн бүтцийг нэгтгэнэ.
		let mergedItems = index > -1 ? [...parentItems, ...current.values[index].value.items] : parentItems;
		// Дэд элементтэй эсэхийг шалгана.
		if ('items' in current && current.items.length > 0) {
			current.items.forEach((child: any) => {
				// Дэд элементүүдийн layout-г цуглуулна.
				collect(mergedItems, child);
			});
		} else {
			let clone = { ...current };
			if (index > -1) {
				clone.values = JSON.parse(JSON.stringify(clone.values));
				clone.values[index].value.items = mergedItems;
			}
			result[current.id] = clone;
		}
	};
	collect([], item);
	return result;
}

export const setMetaTags = (layout: any, item: any) => {
	var self = this;
	/*var addMetaTag = function(prop, content) {
		var meta = document.createElement('meta');
		meta.setAttribute('property', prop);
		meta.content = content;
		document.getElementsByTagName('head')[0].appendChild(meta);
	};*/
	var fields = collectFields(layout);
	fields.forEach(function (field) {
		var value = getValue(item, field.id);
		if (value != null) {
			switch (field.meta) {
				case 'title':
					document.title = value;
					//addMetaTag('og:title', value);
					break;
				/*case 'image':
					addMetaTag('og:image', document.location.origin + value);
					break;
				case 'description':
					addMetaTag('og:description', value);
					break;*/
			}
		}
	});
}