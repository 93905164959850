import { useState, FC, useContext, useEffect } from 'react';
import EditElementProps from '../../types/EditElementProps';
import { Link } from 'react-router-dom';
import { getCategory, getValue, collectLayout, getValues } from '../../utils/valueUtils';
import Builder from './Builder';
import Modal from '../modal/Modal';
import JsonModal from '../modal/JsonModal';
import PermissionModal from '../modal/PermissionModal';
import { SiteContext } from '../../contexts/SiteContext';
import { UserContext } from '../../contexts/UserContext';
import ElementProps from '../../types/ElementProps';
import { ItemContext } from '../../contexts/ItemContext';
// tree-edit
type BAProps = {
    After: typeof After
}
const Dg43: FC<EditElementProps> & BAProps = ({ properties, onChange, value }) => {
    const { item } = useContext(ItemContext)
    const { site } = useContext(SiteContext);
    const { user } = useContext(UserContext);
    const addIdItems = (data: any) => {
        const addItems = (node: any) => {
            if (node.items) {
                node.items.forEach(addItems);
            } else {
                node.items = [];
            }
            if (!('id' in node)) {
                node.id = getNewCategoryId(data);
            }
        }
        if (data) {
            addItems(data);
        }
    }
    useEffect(() => addIdItems(value.value), [value]);
    var getNewCategoryId = (data: any) => {
        var maxId = 0;
        var setMaxId = (node: any) => {
            if (node.items) {
                node.items.forEach(setMaxId);
            }
            if (maxId < node.id) {
                maxId = node.id;
            }
        };
        setMaxId(data);
        return maxId + 1;
    };
    const getLayout = function (fieldId: any) {
        // Layout-ын дугаарыг авна.
        var value = getValue(properties, fieldId);
        // Дугаар олдвол
        if (value !== null) {
            // Системийн тохиргооноос дугаарт харгалзах layout-н тохиргоог авна.
            value = getValue(site.default, value);
            // Layout-н тохиргоо олдвол
            if (value !== null) {
                // Form layout-н тохиргоог буцаана.
                return collectLayout(value, 'form');
            }
        }
    };
    let itemLayouts = getLayout('item');
    let fieldLayouts = getLayout('field');
    const [modalValue, setModalValue] = useState<any>(null);
    const [permissionValue, setPermissionValue] = useState<any>(null);
    var showFieldModal = (element: any) => {
        var type = getValue(element, 'type');
        type = type === null ? 0 : type;
        setModalValue({
            item: element,
            layout: fieldLayouts ? getValue(fieldLayouts[type], 'layout') : defaultLayout,
            title: "№ " + element.id,
            onClose: (item: any) => {
                //onClose(item);
                setModalValue(null);
            }
        });
    };
    let fields: any;
    if (fieldLayouts) {
        fields = getItems(fieldLayouts);
    }
    const addField = function (node: any) {
        var maxId = 0;
        var setMaxId = function (category: any) {
            for (var i in category.fields) {
                if (maxId < category.fields[i].id) {
                    maxId = category.fields[i].id;
                }
            }
            for (var i in category.items) {
                setMaxId(category.items[i]);
            }
        };
        // Бүх ангиллын хувьд давхардахгүй талбарын дугаартай байна.
        getValues(item, properties.id).forEach((value: any) => {
            setMaxId(value);
        });
        if (!node.hasOwnProperty('fields')) {
            node.fields = [];
        }
        node.fields.push({ id: maxId + 1, values: [] });
        onChange(value.value);
    };
    var showNodeModal = (category: any) => {
        var type = getValue(category, 'type');
        type = type === null ? 0 : type;
        setModalValue({
            item: category,
            layout: itemLayouts ? getValue(itemLayouts[type], 'layout') : defaultLayout,
            title: "№ " + category.id,
            onClose: (changedCategory: any) => {
                if (changedCategory) {
                    Object.assign(category, changedCategory);
                }
                setModalValue(null);
            }
        });
    };
    const changePermission = (category: any) => {
        var permission = 'param' in category && 'categoryPermission' in category.param ?
            category.param.categoryPermission : {};
        // default сайтын хуудас ангиллын permission нь default permission болно
        var defperm = getCategory(site, -1).param.categoryPermission;
        setPermissionValue({
            current: permission,
            default: defperm
        });
    };
    const handlePermissionClose = (node: any, current: any) => {
        if (current) {
            if (!('param' in node)) {
                node.param = {};
            }
            node.param.categoryPermission = current;
        }
        setPermissionValue(null);
    }
    const onToggle = () => { }
    const onRemove = () => { }
    const onFieldRemove = () => { }
    const [jsonValue, setJsonValue] = useState(null as any);
    const handleJsonClose = (item: any) => {
        Object.assign(item, item);
        setJsonValue(null);
    }
    return <>
        {value && <Builder root={{ items: value.value ? [value.value] : [] }} onChange={(data: any) => { data && addIdItems(data); onChange(data); }} renderNode={(node: any) => <>
            <div className="tree-node">
                {!node.name && <div className="tree-handle"><span className="fa fa-arrows"></span></div>}
                <a className="title" onClick={() => { showNodeModal(node); }}>{node.name || node.id}.{getValue(node, 'title')}</a>
                <div className="tools">
                    {node.items && node.items.length > 0 && <a className="collapser" onClick={onToggle}><span className="{'fa-chevron-right': collapsed, 'fa-chevron-down': !collapsed}"></span></a>}
                    {fields ? <a className="edit-field" onClick={() => { addField(node); }}><span className="fa fa-plus" title="Талбар нэмэх"></span></a> : null}
                    {user.type === 1 ? <Link className="edit-layout" to={'/layout/' + value.id + '/node/' + node.id + '.html'}><span className="fa fa-file-text" title="Бүтэц"></span></Link> : null}
                    <a className="json" onClick={() => setJsonValue(node)}><span className="fa fa-file-code-o" title="JSON"></span></a>
                    {user.type === 1 ? <a className="edit-param" onClick={() => { changePermission(node); }} title="Тохируулах"><span className="fa fa- gear"></span></a> : null}
                    <a className="remove" onClick={onRemove}><span className="fa fa-times" title="Устгах"></span></a>
                </div>
            </div>
            {user.type === 1 && node.fields && <ul>
                {node.fields.map((item: any, i: number) => <Field key={i} node={item} showFieldModal={showFieldModal} onRemove={onFieldRemove} />)}
            </ul>}
        </>} />}
        <JsonModal item={jsonValue} visible={jsonValue != null} onClose={handleJsonClose} />
        {modalValue ? <Modal layout={modalValue.layout} item={modalValue.item} title={modalValue.title} visible={modalValue != null} onClose={modalValue.onClose} /> : null}
        <PermissionModal item={permissionValue} visible={permissionValue != null} onClose={handlePermissionClose} title={''} />
    </>
}
/*function getItems(layouts: any) {
    return Object.keys(layouts).map(function (key) {
        const field = layouts[key];
        return {
            values: [
                { field: 'type', value: field.id },
                { field: 'title', value: getValue(field, 'title') }
            ]
        }
    });
}*/
// Обьектын талбарын утга бүрийг тохирох хэлбэрийн обьект болгож,
// массив үүсгэж буцаана.
const getItems = (layouts: any) => Object.keys(layouts).map((key: any) => {
    var layout = layouts[key];
    var result: any = {
        values: [
            { field: 'type', value: layout.id },
            { field: 'title', value: getValue(layout, 'title') }
        ]
    }
    if (getValue(layout, 'contain') != null || getValue(layout, 'exclude') != null) {
        result.items = [];
    }
    return result;
});
const defaultLayout = require('../../json/default-field.json');
const Field: FC<FieldProps> = ({ node, onRemove, showFieldModal }) => {
    const [jsonValue, setJsonValue] = useState(null as any);
    const handleJsonClose = (item: any) => {
        Object.assign(item, item);
        setJsonValue(null);
    }
    return <li>
        <a className="title" onClick={() => { showFieldModal(node) }}>{node.id}. {getValue(node, 'title')}</a>
        <div className="tools">
            <a className="json" onClick={() => { setJsonValue(node) }}><span className="fa fa-file-code-o" title="JSON"></span></a>
            <a className="remove" onClick={() => { onRemove(node) }}><span className="fa fa-times" title="Устгах"></span></a >
        </div >
        <JsonModal item={jsonValue} visible={jsonValue != null} onClose={handleJsonClose} />
    </li>
}
type FieldProps = {
    node: any;
    onRemove: any;
    showFieldModal: any;
};
const After: FC<ElementProps> = ({ properties }) => {
    const { site } = useContext(SiteContext);
    const getLayout = (fieldId: any) => {
        var value = getValue(properties, fieldId);
        if (value !== null) {
            value = getValue(site.default, value);
            if (value !== null) {
                return collectLayout(value, 'form');
            }
        }
    };
    const itemLayouts = getLayout('item');
    const [widgets, setWidgets] = useState({ items: itemLayouts ? getItems(itemLayouts) : [] });
    return <div className="tabs">
        <ul className="tab-title">
            <li className="active">Элемент</li>
        </ul>
        <div className="tab-content">
            <div className="elements active">
                <Builder root={widgets}
                    renderNode={node => <div>{getValue(node, 'title')}</div>} />
            </div>
        </div>
    </div>
}
Dg43.After = After;
export default Dg43;