import { useEffect } from 'react';
import { RouterProvider } from "react-router-dom";
import { SiteProvider } from './contexts/SiteContext';
import { UserProvider } from './contexts/UserContext';
import { LayoutProvider } from './contexts/LayoutContext';
import mainRouter from './utils/mainRouter';

const handleScroll = () => {
  var offset = 0;
  var className = 'scrolled';
  var regex = /\s*scrolled/i;
  if (window.scrollY > offset) {
    if (!document.body.className.includes(className)) {
      document.body.className += ' ' + className;
    }
  } else {
    document.body.className = document.body.className.replace(regex, '');
  }
}

function App() {
  // Веб рүү орох үед дуудагдана.
  useEffect(() => {
    // загварын файлыг татна.
    // loadCss("/upload/theme/style.css");
    // Хуудсыг доош нь гүйлгэхэд body тагийн class-д scrolled утгыг нэмнэ
    window.addEventListener('scroll', handleScroll);
    if (window.self !== window.top) {
      // Хэрвээ iframe дотор дуудсан бол
      document.body.className += ' iframe';
    }
  }, []);
  return <SiteProvider>
    <UserProvider>
      <LayoutProvider>
        <RouterProvider router={mainRouter} />
      </LayoutProvider>
    </UserProvider>
  </SiteProvider>
}

export default App;