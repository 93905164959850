import { FC } from 'react';
import WidgetProps from '../../types/WidgetProps';
import Element from '../Element';
// Мөр
const Dg5001: FC<WidgetProps> = ({ properties }) => {
    return <div className={properties.class}>
        {properties.items?.map((element: any, i: number) =>
            <Element key={i} properties={element} />)}
    </div>
}
export default Dg5001;