import { createContext, ReactNode, useEffect, useState } from 'react';
import { LayoutContextType } from '../types/LayoutContextType';

export const scope = {
  main: 0,
  payment: 1,
}

export const LayoutContext = createContext<LayoutContextType | undefined>(undefined);


interface LayoutProviderProps {
  children: ReactNode;
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [key, setKey] = useState('');
  const [scope, setScope] = useState(0);

  useEffect(() => {
    
  }, [])

  const value = { key, setKey, scope, setScope }

  return <LayoutContext.Provider value={value}>
    {children}
  </LayoutContext.Provider>
}
