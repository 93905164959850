import { FC } from 'react';
import EditElementProps from '../../types/EditElementProps';
import { Editor } from '@tinymce/tinymce-react';
// rich-edit
const Dg20: FC<EditElementProps> = ({ onChange, value }) => {
	let tinymceOptions: any = {
		relative_urls: false,
		language: 'mn_MN',
		height: 300,
		plugins: [
			"domogmedia advlist autolink lists link image charmap print preview hr anchor",
			"searchreplace wordcount visualblocks visualchars code fullscreen",
			"insertdatetime media nonbreaking save table contextmenu directionality",
			"emoticons template paste textcolor help"
		],
		toolbar: "undo redo | styleselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | domogmedia | link image | media | forecolor backcolor emoticons",
		/* TODO: templates: [
			{title: 'Some title 1', description: 'Some desc 1', content: 'My content'},
			{title: 'Some title 2', description: 'Some desc 2', url: 'development.html'}
		],*/
		fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
		image_advtab: true,

		images_upload_handler: (blobInfo: any, progress: any) => new Promise((resolve, reject) => {
			var xhr: any, formData;
			xhr = new XMLHttpRequest();
			xhr.withCredentials = false;
			xhr.open('POST', 'postAcceptor.php');
			xhr.onload = function () {
				var json;

				if (xhr.status != 200) {
					reject('HTTP Error: ' + xhr.status);
					return;
				}
				json = JSON.parse(xhr.responseText);

				if (!json || typeof json.location != 'string') {
					reject('Invalid JSON: ' + xhr.responseText);
					return;
				}
				resolve(json.location as string);
			};
			formData = new FormData();
			formData.append('file', blobInfo.blob(), blobInfo.filename());
			xhr.send(formData);
		})
	};
	return <Editor initialValue={value.value} init={tinymceOptions}
		onBlur={e => onChange(e.target.getContent())} />;
}
export default Dg20;