import { FC } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import * as valueUtils from '../../utils/valueUtils';
import WidgetProps from '../../types/WidgetProps';
// Google map
var key;
const Dg5003: FC<WidgetProps> = ({ properties }) => {
    var latitude = valueUtils.getValue(properties, 'latitude');
    var longitude = valueUtils.getValue(properties, 'longitude');
    var label = valueUtils.getValue(properties, 'labelContent');
    var zoom = valueUtils.getValue(properties, 'zoom');
    var location = { lat: latitude, lng: longitude };
    key = valueUtils.getValues(properties, 'key');
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: key
    });
    return isLoaded ? <div className={properties.class}>
        <GoogleMap center={location} zoom={zoom} />
    </div> : null;
}
export default Dg5003;