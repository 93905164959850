import { FC, useEffect, useState } from 'react';
import Element from './Element';
import Item from '../types/Item';
import { ItemContext } from '../contexts/ItemContext';
const ItemElement: FC<ItemProps> = ({ layout, item }) => {
    const [_item, setItem] = useState<Item>(item);
    useEffect(() => {
        setItem(item)
    }, [item]);

    return <ItemContext.Provider value={{ item: _item, setItem }}>
        <Element properties={layout} />
    </ItemContext.Provider>
}
type ItemProps = {
    item: Item;
    layout: any;
}
export default ItemElement;