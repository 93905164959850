
import { FC, useContext } from 'react';
import EditElementProps from '../../types/EditElementProps';
import { getValue, reduceValues } from '../../utils/valueUtils';
import { ItemContext } from '../../contexts/ItemContext';
// list-edit
const Dg3: FC<EditElementProps> = ({ properties, onChange, value }) => {
    const { item } = useContext(ItemContext)
	const { title, valueType } = reduceValues(properties);
	let options = getValue(properties, 'values');
	return <>
		<select value={properties.value}>
			<option value="">---{title}---</option>
			{item.values.filter((value: any) => value.field === properties.id).map((value: any) => <option key={value} value={value}>{value}</option>)}
		</select>
		{false && <input type="checkbox" ng-true-value="1" ng-false-value="0" className="attr.class"
			ng-repeat="value in item.values | filter: {field:properties.id}" value={properties.value} />}
		{false && <div ng-if="false" ng-repeat="value in item.values | filter: {field:properties.id}" className="radio-group">
			<label ng-repeat="(k, v) in options">
				<input type="radio" value={options.value} /> {/*v*/}
			</label>
		</div>}
	</>;
}
export default Dg3;