import { FC, useContext } from 'react';
import EditElementProps from '../../types/EditElementProps';
import Element from '../Element';
import { ItemContext } from '../../contexts/ItemContext';
/**
 * json-edit
 * @param param0 
 * @returns 
 */
const Dg40: FC<EditElementProps> = ({ properties, onChange }) => {
    const { item } = useContext(ItemContext)
    if (properties.id === 'desc') {
        if (!('param' in item)) {
            item.param = {};
        }
        item.param.json = true;
    }
    var values = properties.form.fields.fields;
    var keys = Object.keys(values);
    let rows = keys.length > 0 ? values[keys[0]] : [];
    values = item.fields[properties.id];
    /* TODO: if (!angular.isObject(values)) {
        if (angular.isString(values)) {
            item.fields[properties.id] = JSON.parse(values);
        } else {
            item.fields[properties.id] = {};
        }
        values = item.fields[properties.id];
    }*/
    for (var i in values) {
        if (values[i] === null) {
            values[i] = {};
        }
    }
    const add = function () {
        var min = 0;
        for (var i in values) {
            if (min > parseInt(i)) {
                min = parseInt(i);
            }
        }
        values[min - 1] = {};
    };
    return <>
        <div ng-repeat="(i, value) in values">
            <Element ng-repeat="element in rows" properties="element" />
        </div>
        <button ng-if="properties.multi" type="button" className="btn btn-success" onClick={add}><i className="fa fa-plus"></i></button>
    </>
}
export default Dg40;