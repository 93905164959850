import { FC } from 'react';
import ElementProps from '../../types/ElementProps';
import {getValue} from '../../utils/valueUtils';
/**
 * image-view
 * @param param0 
 * @returns 
 */
const Dg1003: FC<ElementProps> = ({ properties, value }) => {
  let image;
  var mode = getValue(properties, 'mode');
  if (mode) {
    var height = getValue(properties, 'height');
    var width = getValue(properties, 'width');
    var urlPrefix = '/image/' + width + mode + height + '/';
    image = value.value.replace('/upload/', urlPrefix);
  } else {
    image = value.value;
  }
  return <img src={image} />;
}

export default Dg1003;