import ItemElement from '../components/ItemElement';
import { useContext, useEffect, useState } from 'react';
import * as MediaService from '../services/MediaService';
import { SiteContext } from '../contexts/SiteContext';

function Theme() {
  const [layout, setLayout] = useState({ items: null })
  const { site } = useContext(SiteContext);
  document.title = 'Загвар';

  useEffect(() => {
    MediaService.getConfigLayout()
      .then(response => response.json()).then(layout => {
        setLayout(layout)
      });
  }, []);
  return layout.items && <ItemElement layout={layout} item={site} />;
}
export default Theme;