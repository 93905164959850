import { useEffect, FC, useState, useContext } from 'react';
import * as valueUtils from '../utils/valueUtils';

import Dg2 from './edit/Dg2';
import Dg3 from './edit/Dg3';
import Dg4 from './edit/Dg4';
import Dg5 from './edit/Dg5';
import Dg6 from './edit/Dg6';
import Dg7 from './edit/Dg7';
import Dg8 from './edit/Dg8';
import Dg9 from './edit/Dg9';
import Dg10 from './edit/Dg10';
import Dg11 from './edit/Dg11';
import Dg14 from './edit/Dg14';
import Dg15 from './edit/Dg15';
import Dg16 from './edit/Dg16';
import Dg17 from './edit/Dg17';
import Dg18 from './edit/Dg18';
import Dg20 from './edit/Dg20';
import Dg30 from './edit/Dg30';
import Dg40 from './edit/Dg40';
import Dg41 from './edit/Dg41';
import Dg42 from './edit/Dg42';
import Dg43 from './edit/Dg43';
import Dg50 from './edit/Dg50';
import ElementProps from '../types/ElementProps';
import { ItemContext } from '../contexts/ItemContext';

const EditElement: FC<ElementProps> = ({ properties, type }) => {
    const { item } = useContext(ItemContext)
    const title = valueUtils.getValue(properties, 'title');
    const multi = valueUtils.getValue(properties, 'multi');
    // Утга өөрчлөгдөх тоолонд DOM шинэчлэхийн тулд values-г state-д хийлээ.
    const [values, setValues] = useState(item.values ?? []);
    const elements: any = {
        Dg2: Dg2,
        Dg3: Dg3,
        Dg4: Dg4,
        Dg5: Dg5,
        Dg6: Dg6,
        Dg7: Dg7,
        Dg8: Dg8,
        Dg9: Dg9,
        Dg10: Dg10,
        Dg11: Dg11,
        Dg14: Dg14,
        Dg15: Dg15,
        Dg16: Dg16,
        Dg17: Dg17,
        Dg18: Dg18,
        Dg20: Dg20,
        Dg30: Dg30,
        Dg40: Dg40,
        Dg41: Dg41,
        Dg42: Dg42,
        Dg43: Dg43,
        Dg50: Dg50
    };
    const Tag = elements[type ?? ''];
    // Утгагүй бол хоосон утга нэмнэ,
    // хэрвээ олон утга авдаг бөгөөд олон хоосон утгатай бол
    // нэг л хоосон утга үлдээнэ.
    const handleChange = () => {
        let hasEmpty = false;
        let hasValue = false;
        // Бүх утгаар дамжина.
        for (let i in item.values) {
            const value = item.values[i];
            if (value.field === properties.id) {
                // Уг талбарын утга бол
                if (value.value == null || value.value === '') {
                    // Утга нь хоосон бол
                    if (hasEmpty) {
                        // Өмнө нь хоосон утга байсан бол
                        // энэ хоосон утгыг устгана
                        delete item.values[i];
                    } else {
                        // Өмнө нь хоосон утга байгаагүй бол
                        // энэ утгыг үлдээгээд хоосон утга байсны тэмдэглэнэ.
                        hasEmpty = true;
                    }
                }
                // Ядаж нэг утга байсныг тэмдэглэнэ.
                hasValue = true;
            }
        }
        if (!hasValue || multi && !hasEmpty) {
            item.values.push({ field: properties.id, value: null });
        }
        setValues(item.values.filter((value: any) => value));
    };
    useEffect(() => {
        handleChange();
    }, [item]);

    return Tag ? <div className={properties.class}>
        <label>{title}</label>
        {Tag.Before ? <Tag.Before properties={properties} /> : null}
        {values.filter((value: any) => value.field === properties.id).map((value: any, i: number) =>
            <Tag key={i} properties={properties} value={value}
                onChange={(data: any) => { value.value = data; handleChange() }} />)}
        {Tag.After ? <Tag.After properties={properties} /> : null}
    </div> : <div>Unsupported Element: {type}</div>;
}

export default EditElement;