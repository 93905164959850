import { FC } from 'react';
import EditElementProps from '../../types/EditElementProps';
import { getValue } from '../../utils/valueUtils';
// text-edit
const Dg15: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const title = getValue(properties, 'title');
	const readonly = getValue(properties, 'readonly');
	const required = getValue(properties, 'required');
	return <input type="text" placeholder={title} title={title} required={required}
		readOnly={readonly} value={value.value ?? ''} onChange={e => onChange(e.target.value)} />;
}
export default Dg15;