import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as MeService from '../services/MeService';
import { UserContext } from '../contexts/UserContext';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const handleLogin = async (event: any) => {
        event.preventDefault();
        await MeService.signIn(email, password)
        const res = await MeService.get()
        setUser(await res.json())
    };
    useEffect(() => {
        if (user.id > 0) {
            navigate('/');
        }
    }, [user.id])
    return <div className="login-box">
        <h2></h2>
        <form role="form" onSubmit={handleLogin} className="box">
            <div className="form-group">
                <label>Цахим шуудан</label>
                <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="form-group">
                <label>Нууц үг</label>
                <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} />
            </div>
            <div className="form-actions">
                <input type="checkbox" className="uniform" value="" /><label>Сануулах</label>
                <button type="submit" className="btn btn-primary"><i className="fa fa-unlock"></i> Нэвтрэх</button>
            </div>
            <p>
                <Link to="/password.html">Нууц үгээ мартсан уу?</Link>
            </p>
            <p>
                <Link to="/register.html">Шинээр бүртгүүлэх</Link>
            </p>
        </form>
    </div>
}
export default Login;