import * as Service from './Service';
/**
 * Веб үзэж буй хэрэглэгчийн мэдээллийг удирдах сервис
 */
const alias = 'users/me'
/**
 * Өгөгдсөн дугаартай мэдээллийг авна
 * @param {number} id Авах мэдээллийн дугаар
 * @returns Мэдээлэл
 */
export const get = () => Service.get(alias, undefined)
export const signIn = async (email: string, password: string) => {
    const res = await Service.add(alias, { email: email, password: password })
    const data = await res.json()
    localStorage.setItem('token', data.access_token)
};
export const signOut = async () => {
    await Service.add(alias, {})
    localStorage.clear()
};
export const newPass = (email: string) => Service.add(alias, { email: email });
export const signUp = (email: string, password: string) => Service.edit(alias, undefined, { email: email, password: password });