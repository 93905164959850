import * as ItemService from "../services/ItemService";

const itemsLoader = async (route: any) => {
    const url = new URL(window.location.href);
    let status = parseInt(url.searchParams.get('status') || '0');
    let parentId = parseInt(url.searchParams.get('parent') || '0');
    status = status > 0 ? status : 30;
    const current = parseInt(url.searchParams.get('page') || '0') > 1 ? parseInt(url.searchParams.get('page') || '0') : 1;

    let q: any = {
        status: status,
        category: parseInt(route.params.categoryId),
        $orderby: { created: 'desc' }
    };
    console.log('change to ', q.category, document.location.href);
    if (parentId > 0) {
        q.parent = parentId;
    }
    let queryParam = { limit: 50, q: JSON.stringify(q), offset: 0 };
    queryParam.offset = (current - 1) * queryParam.limit;
    console.log('query param', queryParam)
    return await ItemService.query(queryParam)
}

export default itemsLoader;