import { createBrowserRouter } from "react-router-dom";
import Home from '../pages/Home';
import Page from '../components/Page';
import PageLayout from '../components/PageLayout';
import Category from '../components/Category';
import Table from '../components/Table';
import Media from '../pages/Media';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Register from '../pages/Register';
import Password from '../pages/Password';
import Root from '../pages/Root';
import itemLoader from '../loaders/itemLoader';
import itemsLoader from '../loaders/itemsLoader';
import Theme from "../pages/Theme";


const mainRouter = createBrowserRouter([
    {
        path: "/",
        Component: Root,
        children: [
            {
                index: true,
                Component: Home,
            },
            {
                loader: itemLoader,
                path: "items/:itemId.html",
                Component: Page,
            },
            {
                loader: itemLoader,
                path: "items/:itemId/:layout.html",
                Component: Page,
            },
            {
                loader: itemLoader,
                path: "categories/:categoryId/items/:itemId/:layout.html",
                Component: Page,
            },
            {
                loader: itemsLoader,
                path: "categories/:categoryId/items.html",
                Component: Category,
            },
            {
                loader: itemsLoader,
                path: "categories/:categoryId/table.html",
                Component: Table,
            },
            {
                path: "layout/:valueId/node/:nodeId.html",
                Component: PageLayout,
            },
            {
                path: "media/*",
                Component: Media,
            },
            {
                path: "media.html",
                Component: Media,
            },
            {
                path: "login.html",
                Component: Login,
            },
            {
                path: "logout.html",
                Component: Logout,
            },
            {
                path: "register.html",
                Component: Register,
            },
            {
                path: "password.html",
                Component: Password,
            },
            {
                path: "theme.html",
                Component: Theme,
            },
        ]
    },

])

export default mainRouter;