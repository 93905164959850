import { FC, useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../contexts/SiteContext';
import { getCategory } from '../../utils/valueUtils';
import ItemElement from '../ItemElement';

const ItemModal: FC<ItemModalProps> = ({ item, visible, onClose }) => {
	const { site, getItemLayout } = useContext(SiteContext);

	const handleSave = (value: any) => {
		onClose(value);
	};
	const layoutName = 'form';
	const category = getCategory(site, item.category);
	const [layout, setLayout] = useState<any>()
	useEffect(() => {
		// Мэдээллийн ангиллаас хамаарч харуулах хэлбэрийг өөрчилнө
		const category = getCategory(site, item.category);
		if (category) {
			setLayout(getItemLayout(category, layoutName));
		}
	}, [layoutName, item.category]);
	return visible ? <div className="modal-wrapper">
		<div className='modal'>
			<h3>{category.title}: {item.title}</h3>

			<ItemElement layout={layout} item={item} />
			<button className='close' onClick={onClose}>
				<span className='fa fa-times'></span>
			</button>
		</div>
	</div> : null;
}
type ItemModalProps = {
	visible: boolean;
	onClose: any;
	item: any;
};
export default ItemModal;